const getApiEndpoint = () => {
  const isLocalhost = window.location.hostname === "localhost";

  const sandboxEndpoint = "http://localhost:8000";
  const productionEndpoint =
    "https://api-super-urgent-eb1c859fd8ae.herokuapp.com";

  return isLocalhost ? sandboxEndpoint : productionEndpoint;
};

const API_ENDPOINT = getApiEndpoint();

export default API_ENDPOINT;
