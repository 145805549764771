import React from "react";
import logo from "../../assets/Symbol.svg";
import sweden from "../../assets/Flag_of_Sweden.svg";

const NavBar = () => {
  return (
    <nav className="bg-navBg h-20">
      <div className=" mx-auto flex justify-between items-left">
        <div className="flex items-center items-left">
          <div className="flex items-center h-20  p-6">
            <img src={logo} className="w-8 h-8" alt="Logo" />
          </div>
        </div>
        <div className="flex items-center mr-4">
          <div className="rounded-full overflow-hidden">
            <img src={sweden} className="w-6 h-6 object-cover" alt="Sweden" />
          </div>
          <a href="#" className="text-fontColor ml-2">
            Svenska
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
